import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Section from "@components/section"
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"
import { Heading2, sectionMd } from "@styles/ui"
import BgImageSection from "@components/narcolepsy/BgImageSection/"
import PatientVoice from "@components/PatientVoice"

import keypointsIcon1 from "@images/icons/round/icon-prescription.svg"
import keypointsIcon2 from "@images/icons/round/icon-dosing.svg"
import keypointsIcon3 from "@images/icons/round/icon-clipboard-fade.svg"

const TransitioningPage = ({ location, data }) => {
  const keyPoints = [
    {
      image: keypointsIcon1,
      text: (
        <>
          Your doctor is recommended to&nbsp;initially prescribe XYWAV at
          the&nbsp;same dose and regimen as your XYREM treatment.
        </>
      ),
    },
    {
      image: keypointsIcon2,
      text: (
        <>
          Take XYWAV exactly as your doctor instructs. If needed,
          your&nbsp;doctor may adjust your dosage or dosing routine.
        </>
      ),
    },
    {
      image: keypointsIcon3,
      text: (
        <>
          Your doctor won&apos;t need to re-enroll you in XYWAV and XYREM REMS,
          since you&apos;re already enrolled.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} exitModalColour="purple">
      <Seo
        title="Transitioning from XYREM® (sodium oxybate) to XYWAV | XYWAV®"
        description="Learn what to expect when transitioning from XYREM® to XYWAV® (calcium, magnesium, potassium, and sodium oxybates). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <StaticHero
        eyebrow="Transitioning from XYREM"
        gradient="purple-red"
        textWide
        jumpLinks={[
          { text: "XYWAV vs XYREM", link: "#xyrem" },
          {
            text: "Transitioning: What to Expect?",
            link: "#transitioning",
          },
        ]}
      >
        Your doctor can transition you from XYREM
        <sup>&reg;</sup>
        <br tw="hidden xl:(block)" />
        (sodium oxybate) oral solution to low&#8209;sodium
        <br tw="hidden lg:(block)" /> oxybate, XYWAV
      </StaticHero>

      <BgImageSection
        imageData={data}
        widerImage
        smallImageMargin
        mobileAspectRatio={"90%"}
        minHeightDesktop="23.75rem"
        altText="A woman working on her computer"
      >
        <>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`mb-6 md:max-w-[36rem]`]}>
            Ready to address the high&#8209;sodium elephant in the room?
          </h2>
          <p tw="text-2xl leading-[1.25] font-black mb-2.5 md:(text-3xl leading-[1.25])">
            Both XYWAV and XYREM contain oxybate as their active ingredient, but
            XYWAV is a lower-sodium formulation.
          </p>
          <p tw="text-base">
            At the maximum recommended 9&#8209;g nightly dose, XYWAV has
            131&nbsp;mg of sodium vs ~1640&nbsp;mg with XYREM, a
            high&#8209;sodium oxybate.{" "}
            <span tw="font-black">
              This means that XYWAV has ~1509&nbsp;mg less sodium per night than
              XYREM at the 9-g nightly dose.
            </span>
          </p>
        </>
      </BgImageSection>

      {/* Because XYWAV has significantl... */}
      <Section id="xyrem" tw="lilac-gradient-vertical py-6 md:py-12">
        <Container grid>
          <div
            tw="md:col-span-12
              before:(block mb-6 h-1.5 rounded-md gradientRed mx-auto w-[100px] lg:w-[8.5rem])"
          >
            <h2 css={[Heading2, tw`text-center`]}>
              Because XYWAV has significantly less sodium compared to XYREM,
              <br tw="hidden xl:(block)" /> choosing XYWAV over XYREM can help
              reduce the risk for developing cardiovascular disease
            </h2>
          </div>
        </Container>

        <div tw="pt-4 md:pt-[50px]">
          <Container grid addedStyles={tw`lg:(gap-x-[1.5625rem])`}>
            <div css={[tw`md:(col-start-1 col-span-6) lg:(text-xl)`]}>
              <p tw="mb-5 text-base lg:mb-6">
                In a study of 85 people with narcolepsy who transitioned from
                high&#8209;sodium XYREM to low&#8209;sodium XYWAV, participants
                completed weekly lookback diaries and questionnaires. They were
                asked “Why are you transitioning?”:
              </p>
              <ul tw="mb-5 lg:mb-6 list-disc text-base leading-[20px] pr-2 [padding-inline-start: 20px] md:(text-xl leading-[25px] pr-0)">
                <li tw="mb-3 text-base lg:mb-5">
                  Over a third—<span tw="font-black">38.8%</span>—said they
                  transitioned to avoid current cardiovascular conditions
                </li>
                <li tw="mb-3 text-base lg:mb-5">
                  Almost all—<span tw="font-black">92.9%</span>—said that they
                  transitioned for long&#8209;term health benefits due to the
                  lower sodium content of XYWAV compared to XYREM
                </li>
              </ul>
            </div>
            <div css={[tw`md:(col-start-7 col-span-6) lg:(text-xl)`]}>
              <p tw="text-base mb-6">
                A potential limitation of the study was that 16% of participants
                discontinued for potentially&nbsp;not being satisfied with XYWAV
                treatment. This could have impacted the results to appear more
                favorable to XYWAV.
              </p>
            </div>
          </Container>
        </div>
      </Section>

      <PatientVoice
        quote={
          <span tw="inline-block lg:pr-20">
            When I transitioned from XYREM to XYWAV, my doctor just told me to
            start on the dose I was on...
            <br />
            I&nbsp;basically just picked up where I left&nbsp;off.*&rdquo;
          </span>
        }
        patient={"Oscar"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={data}
        disclaimer={
          <>
            <span tw="md:(block max-w-[550px])">
              <span tw="relative -ml-1">*</span>
              After transitioning from XYREM to XYWAV some people may require
              changes to their dosage based on efficacy and tolerability.
            </span>
          </>
        }
        imageRight
        footnote="Compensated for his time. Results may vary."
      />

      {/* key points */}
      <section
        id="transitioning"
        css={[sectionMd, tw`relative lilac-gradient-vertical`]}
      >
        <Container>
          <h2
            css={[
              Heading2,
              tw`text-center mx-auto mb-[1.875rem] lg:(max-w-[29rem] mb-[3.125rem]) before:(block mb-6 h-1.5 rounded-md gradientRed mx-auto w-[100px] lg:(w-[135px]))`,
            ]}
          >
            What to expect when transitioning to XYWAV
          </h2>
          <ul tw="list-disc pl-2 mt-[0.9375rem] flex flex-col md:(mt-[1.875rem]) lg:(flex-row justify-center gap-x-16)">
            {keyPoints.map((point, index) => (
              <li
                key={index}
                tw="mb-[0.9375rem] flex gap-[1.5rem] items-center md:mb-[1.875rem] lg:max-w-[23.4375rem]"
              >
                {point.image && (
                  <img
                    tw="w-[100px] lg:(w-[7.5rem])"
                    src={point.image}
                    alt={point.imageAlt || ""}
                  />
                )}
                <p tw="text-base pr-[1.5rem] lg:pr-1">{point.text}</p>
              </li>
            ))}
          </ul>
        </Container>
      </section>

      <section tw="py-[1.875rem] bg-lilac">
        <Container>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md left-1/2 transform -translate-x-1/2) md:before:(mb-[25px]) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`mb-[1.875rem] text-center`]}>
            Have more questions about XYWAV?
          </h2>
          <div tw="flex justify-center">
            <PrimaryCta url={"/narcolepsy/faq/"} addedStyles={tw`w-fit`}>
              SEE FAQ
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default TransitioningPage

export const query = graphql`
  query {
    colImage: file(
      relativePath: { eq: "transitioning-from-xyrem/less-sodium.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE)
      }
    }
    colImageTwo: file(
      relativePath: { eq: "transitioning-from-xyrem/bottle.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 470, placeholder: NONE)
      }
    }
    bgImageSection: file(
      relativePath: {
        eq: "transitioning-from-xyrem/woman-on-computer-sodium-elephant.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: {
        eq: "transitioning-from-xyrem/woman-on-computer-sodium-elephant-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "transitioning-from-xyrem/oscar-headshot.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 546, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "transitioning-from-xyrem/oscar-headshot-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    patientVoiceBrooke: file(
      relativePath: { eq: "transitioning-from-xyrem/brooke-desktop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceBrookeMobile: file(
      relativePath: { eq: "transitioning-from-xyrem/brooke-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    bottleImage: file(relativePath: { eq: "what-is-xywav/bottle.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: NONE)
      }
    }
    saltyElephant: file(
      relativePath: { eq: "what-is-xywav/salty-elephant.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, placeholder: NONE)
      }
    }
  }
`
